.LoadingBeforeFetchInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 60%;
}

.logo {
    width: 45% ;
    max-width: 80px;
    height: auto; 
}

.loadingProgressBarContainer {
    width: 180px;
    height: 8px;
    border-radius: 6px;
    border: none;
    background-color: #e9e9e9;

    .loadingProgressBar {
        width: 50px;
        height: 8px;
        border-radius: 4px;
        background-color: #1877f2;
        animation: LoadingProgressBar 2s linear infinite;
    }
}

@keyframes LoadingProgressBar {
    0% {
        margin-right: 0px;
    }

    50% {
        margin-right: 130px;
    }

    100% {
        margin-right: 0px;
    }
}
