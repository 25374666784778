.packCardSchoolCustomInCart {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 10px;
  }
  .tinyInCard {
    width: 100%;
    background-image: url("../../assets/images/backgrounds/tiny-background-for-cart.png");
    background-repeat: round;
    aspect-ratio: 35/22;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .detailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: fit-content;
    overflow: hidden;
    transition: all linear 0.3s;
  }
  