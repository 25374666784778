.addressSelection {
  position: relative;
  display: flex;
  width: 100%;
  height: 48px;
  padding: 4px 14px;
  align-items: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin-top: 12px;
  .addressListContainer {
    padding: 8px;
    gap: 12px;
    position: absolute;
    width: 100%;
    top: 40px;
    right: 0;
    border-radius: 0 0 10px 10px;
    background: #eeeeee;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 4;
    display: flex;
    flex-direction: column;

    .addressName {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .addressItem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      gap: 8px;
    }
    .activeItem,
    .addressItem:hover {
      border-radius: 8px;
      background-color: #abf0d4;
    }
  }
}
