.inputBox {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  label {
    position: absolute;
    font-size: 14px;
    top: 18px;
    transition: all 0.3s ease;
    left: 5px;
    color: theme("colors.black.text");
    [dir="rtl"] & {
      left: unset;
      right: 5px;
    }
  }
  input {
    margin-top: 10px;
    // width: 240px;
    width: 100%;
    height: 36px;
    border-radius: 5px;
    text-align: center;
    background-color: theme("colors.green.100");
    color: theme("colors.black.text");
    // border: 0;
    border: 1px solid theme("colors.black.text");
    &::placeholder {
      opacity: 0;
    }
    &:focus {
      &::placeholder {
        opacity: 1;
      }
      border: 1.5px solid theme("colors.black.text");
      outline: 0;
      color: theme("colors.black.text");
    }
    &:focus,
    &:not(:placeholder-shown),
    &:-webkit-autofill {
      & + label {
        top: 10px;
        left: -5px;
        padding: 0 3px 0 5px;
        font-size: 12px;
        transform: scale(0.75);
        [dir="rtl"] & {
          left: unset;
          right: 0;
        }
      }
    }
  }
  .show-hide-password-icon {
    position: absolute;
    right: 5px;
    top: 19px;
    width: 20px;
    cursor: pointer;
    [dir="rtl"] & {
      right: unset;
      left: 5px !important;
    }
  }
  // apply internal-autofill-selected for saved password
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
}
