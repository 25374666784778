.PackCardContainer {
  display: flex;
  width: 100%;
  height: auto;
  padding: 16px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  flex-wrap: wrap;
  border-radius: 10px;
  background: #ffefea;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.packTray {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;

  .tray {
    width: 100%;
    height: auto;
    position: relative;
  }
  .content {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // justify-content: start;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 5%;
    position: relative;
    left: 0;
    right: 0;
    margin-left: 7%;
    margin-right: 7%;
    height: 85%;
    width: 85%;
    padding: 5% 0;
    // max-height: 130px;
  }
}

.contentNames {
  padding: 6px 12px;
  color: #393939;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  direction: ltr;
}

.price {
  color: #231f20;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
