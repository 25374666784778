// .header-container {
//     direction: rtl;
//     width: 100%;
//     height: 75px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     // color: var(--text-title-color);

//     .select-theme-container,
//     .select-lang-container {
//       width: 45px;
//     }

//     .select-theme-container {
//       cursor: pointer;

//       img {
//         width: 27px;
//         margin: 0 6px;
//         padding: 3px;
//         border-radius: 50%;
//         background-color: #adadad;
//       }

//       // .select-lang-container {

//       // }
//     }

//     .header-brand-container {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       width: auto;

//       span {
//         font-size: 10px;
//       }

//       .header-logo {
//         width: 40px;
//         height: auto;
//         border-radius: 7px;
//       }
//     }

// box-shadow: 1px 1px 2px rgb(168, 168, 168);
.select-lang-container {
  height: fit-content;
  width: 55px;
  position: absolute;
  top: 9px;
  // top: calc(5px + theme("height.header"));
  right: 60px;
  z-index: 3;
  //   [dir="rtl"] & {
  //       right: 20px;
  //   }

  .select-lang-wrapper {
    font-size: 9px;
    position: relative;
    width: 100%;
    // background-color: theme("colors.orange.default");
    border-radius: 15%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4px 0;
    &:hover {
      color: theme("colors.blue.default");
      // .select-language-bar {
      //   height: 84px;
      // }
    }
    // box-shadow: 1px 1px 2px rgb(168, 168, 168);
  }

  .select-language-bar {
    position: absolute;
    overflow: hidden;
    z-index: 99;
    width: auto;
    top: 20px;
    margin: auto;
    border-radius: 5px;
    color: theme("colors.black.text");
    background-color: theme("colors.blue.default");
    transition: height 0.2s ease;
    height: 0;

    .lang-item {
      // color: var(--primary-orange);
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      padding: 1px 6px;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
      //   color: var(--text-color);
    }
  }

  .show-select-language-bar {
    height: 84px;
    // color: theme("colors.blue.default");
  }
}
@media screen and (min-width: 700px) {
  .select-lang-container {
    .select-lang-wrapper {
      background-color: inherit;
    }
  }
}
