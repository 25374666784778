.addFormAddress {
  padding-top: 12px;
  padding-bottom: 12px;
  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .formItem {
      display: flex;
      flex-direction: column;
      label {
        font-size: 12px;
        &::after {
          content: ":";
        }
      }
      textarea,
      input {
        padding: 4px 14px;
        color: #393939;
        width: 100%;
        // max-width: 320px;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        &::placeholder {
          color: #a6a8ab;
        }
        &:focus {
          outline: none;
        }
      }
      input {
        height: 36px;
      }
      input:required {
        border-color: black;
      }
      input:invalid {
        border-color: red;
      }
    }
  }

}
