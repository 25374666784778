.container {
  width: 100%;
  height: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  position: relative;

  .categoriesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 70px;
    max-width: 120px;
    width: 20%;
    min-height: fit-content;
    height: auto;
    gap: 14px;
    // background-color: theme("colors.white.text");
    // background-color: #ffe7e0;
    z-index: 2;
    border-radius: 15px 0 0 15px;
    [dir="rtl"] & {
      border-radius: 0 15px 15px 0;
    }
  }
  .productsContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: baseline;
    width: 80%;
    min-width: 120px;
    min-height: fit-content;
    height: auto;
    // background-color: theme("colors.white.text");
    background-color: #ffe7e0;
    z-index: 2;
    border-radius: 10px;
    margin-right: 0;
    margin-left: 15px;
    [dir="rtl"] & {
      margin-left: 0;
      margin-right: 15px;
      border-radius: 15px 0 0 15px;
    }
  }
  .category {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    // min-height: 110px;
    cursor: pointer;
    // &:not(:last-child) {
    //   border-bottom: 1px solid theme("colors.green.300");
    // }
    .categoryImageContainer {
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65px;
      width: 65px;
      background-color: #fff;
      transition: all ease 0.3s;
      border-radius: 12px;
    }
    .categoryImage {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      // min-width: 35px;
      // border-radius: 50%;
      // border: 3px solid theme("colors.white.DEFAULT");
    }
    .categoryName {
      margin-top: 4px;
      transition: all ease 0.3s;
    }
  }

  .activeCategory {
    .categoryImageContainer {
      background-color: #ffe7e0;
    }
    .categoryImage {
      // border: 3px solid #fea386;
    }
    .categoryName {
      color: #fbbb23;
    }
  }

  .block {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 30px;
    left: 150px;
    z-index: 5;
    background-color: red;
    transition: all ease 2s;
  }

  .product {
    width: 90px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    // box-shadow: 1px 1px 23px 0 theme("colors.black.100");
    margin: 1rem 0.2rem;
    cursor: pointer;
    transition: background-color ease 0.3s;
    font-size: 15px;
    // &:hover {
    //   background-color: #ffe7e0;
    //   // background-color: #fea386;
    //   .productImage {
    //     border-color: #fea386;
    //   }
    // }
    .productImageContainer {
      transition: background-color ease 0.3s;
      background-color: #fff;
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
      // &:hover {
      //   background-color: #ffe7e0;
      // }
    }
    .productImage {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      // box-shadow: 0px 4px 4px 0px #00000040;
      transition: border-color ease 0.3s;
    }
    .productName {
      margin-top: 4px;
    }
  }
}
.PolygonArrow {
  position: absolute;
  transition: top ease 0.3s;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 16px solid #ffe7e0;
  border-bottom: 8px solid transparent;

  [dir="rtl"] & {
    right: -13px;
    transform: rotate(180deg);
  }
  [dir="ltr"] & {
    left: -13px;
  }
}
