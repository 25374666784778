.totalAndCoupon {
  position: absolute;
  bottom: calc(10% - 2vh);
  right: 5%;
  left: 5%;
  background-color: inherit;
  width: 90%;
  height: 130px;
  padding: 0 20px;
}
.couponContainer {
  background-color: #fff;
  height: 42px;
  border-radius: 10px;
  overflow: hidden;
  input{
    outline: none;
    text-align: center;
  }
}
// .orderDate {
//   //   border-bottom: 3px solid theme("colors.blue.default");
//   //   width: 70%;
//   margin-top: 20px;
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   align-items: center;
// }

// .childNameContainer {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   box-shadow: 0 4px 4px 0 hsla(0, 0%, 0%, 0.25);
//   border-radius: 10px;
//   height: 80px;
//   background-color: rgb(226, 226, 226);
//   position: relative;
// }
// .tinyType {
//   position: absolute;
//   bottom: 0;
//   left: 5px;
//   font-size: 13px;
//   color: #000000;
// }
// .details {
//   position: absolute;
//   right: 10px;
//   top: 20px;
//   // color: #000000;
//   cursor: pointer;
//   color: theme("colors.orange.default");
// }
