.PackCardContainer {
  display: flex;
  width: 100%;
  height: auto;
  padding: 16px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  flex-wrap: wrap;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.packTray {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;

  .tray {
    width: 100%;
    height: auto;
    position: relative;
  }
  .content {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 90%;
    max-height: 130px;
    width: auto;
  }
}

.contentNames {
  padding: 6px 12px;
  color: #393939;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  direction: ltr;
}

.price {
  color: #231f20;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
